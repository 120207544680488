<template>
  <div
    class="misc-wrapper"
    style="background:white"
  >
    <b-link class="brand-logo">
      <b-img
        :src="require('@/assets/images/logo/logo.svg')"
        alt="Surfe logo"
        style="max-width:150px"
      />
    </b-link>

    <div class="p-2 p-sm-3">
      <div
        style="margin-top:100px; max-width:1400px"
      >
        <h2 class="mb-10">
          Welcome to Surfe Enrichment Hub 🚀
        </h2>
        <b-row
          v-if="isMounted"
        >
          <b-col
            lg="6"
            md="6"
          >
            <p
              style="font-size: large;"
            >
              Remaining credits: <b>{{ remainingCredits }}</b>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            lg="6"
            md="6"
          >
            <div
              v-for="q in queries"
              :key="q.id"
              class="my-2 p-4"
              style="background-color:#EEEEEE; position:relative"
            >
              <p
                style="position:absolute; top:10px;right:10px; width:13vw; color:#AAA; font-size:small"
              >
                <span
                  v-if="q.status != STATUSES.SUBMITTED"
                >
                  Credits: {{ q.usedCredits }}<br>
                </span>
                {{ q.id }}<br>
                {{ q.createdAt }}<br>
                {{ q.requesterEmail }}
              </p>
              <p>
                Payload size: <b>{{ q.payloadSize }}</b><br>
                <a
                  :href="q.spreadsheetURL"
                  style="color: blue"
                  target="_blank"
                >Link to Spreadsheet</a>
              </p>
              <p class="mb-0">
                <b
                  :style="{color: q.status === STATUSES.COMPLETED
                    ?
                      'green'
                    : q.status === STATUSES.FAILED ? 'red' :
                      'blue'}"
                >{{ q.status }}</b>
              </p>
              <button
                v-if="isSurfeUser && isValidTransition(q.status, STATUSES.COMPLETED)"
                @click="validateQuery(q.id)"
              >
                Validate
              </button>
              <button
                v-if="(isSurfeUser || isRequestOwner(q)) && isValidTransition(q.status, STATUSES.CANCELLED)"
                @click="cancelQuery(q.id)"
              >
                Cancel
              </button>
            </div>
            <div
              v-if="!isMounted"
            >
              Loading queries...
            </div>
            <div
              v-if="queries.length === 0 && isMounted"
            >
              Create a new query to see it here
            </div>
          </b-col>
          <b-col
            lg="6"
            md="6"
          >
            <p class="my-3">
              To use our enrichment services, send a <code>POST</code> request to <code>{{ ehURL }}/auth/queries/persona-search</code> with the <code>Authorization</code> header <code>{{ token }}</code> and payload in the following format:
            </p>

            <pre class="p-2">
{
    "personas": [
        {
            "crmId": "...",
            "name": "Company #1",
            "domain": "company1.domain",
            "titles": [
                "CEO",
                "Head of Operations",
                "Head of Data"
            ],
            "seniority": ["executive", "head"],
            "maxPerEntry": 5
        },
        {
            "crmId": "...",
            "name": "Company #2",
            "domain": "company2.domain",
            "titles": [
                "Team Lead"
            ],
            "seniority": ["default"],
            "maxPerEntry": 1
        }
    ]
}
        </pre>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg, BCol, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'

export default {
  components: {
    BLink,
    BImg,
    BCol,
    BRow,
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_EH_API_KEY,
      ehURL: process.env.VUE_APP_EH_API_URL,
      user: localStorage.getItem('eh_user'),
      token: localStorage.getItem('token'),
      isMounted: false,
      STATUSES: {
        SUBMITTED: 'SUBMITTED',
        PENDING_VALIDATION: 'PENDING_VALIDATION',
        COMPLETED: 'COMPLETED',
        CANCELLED: 'CANCELLED',
        FAILED: 'FAILED',
      },
    }
  },
  computed: {
    ...mapState({
      queries: state => state.api.ehQueries,
      remainingCredits: state => state.api.remainingCredits,
    }),
    isSurfeUser() { return this.user.endsWith('surfe.com') },
  },
  async mounted() {
    await this.$store.dispatch('api/loadEHQueries')
    this.isMounted = true
  },
  methods: {
    async validateQuery(id) {
      try {
        await this.$store.dispatch('api/validateQuery', id)
        await this.$store.dispatch('api/loadEHQueries')
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async cancelQuery(id) {
      try {
        await this.$store.dispatch('api/cancelQuery', id)
        await this.$store.dispatch('api/loadEHQueries')
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    isRequestOwner(query) {
      return query?.requesterEmail === this.user
    },
    isValidTransition(currentStatus, newStatus) {
      const StatusMap = {
        SUBMITTED: [this.STATUSES.CANCELLED],
        PENDING_VALIDATION: [this.STATUSES.COMPLETED, this.STATUSES.CANCELLED],
        COMPLETED: [],
        CANCELLED: [],
        FAILED: [],
      }
      const validTransitions = StatusMap[currentStatus]
      if (!validTransitions) {
        throw new Error('Invalid status: ', currentStatus)
      }

      return validTransitions.includes(newStatus)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
